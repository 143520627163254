import React, { useState } from "react";
import service1 from "../assets/service1.jpg";
import service2 from "../assets/herobtn2.jpg";
import service3 from "../assets/herobtn3.jpg";
import ButtonPrimary from "./ButtonPrimary";
import Overlay from "./Overlay";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube } from "swiper";
import SwiperCore, { Autoplay, A11y } from "swiper";
import "swiper/css";
import "swiper/css/effect-cube";

import "swiper/css/bundle";

SwiperCore.use([Autoplay, A11y]);

function Services() {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () =>
    setIsClicked((prevState) => (prevState ? false : true));

  const handleKeydown = (e) => {
    if (e.key === "Escape") setIsClicked(false);
  };

  const slides1 = [service1, service2, service3];
  const slides2 = [service2, service3, service1];
  const slides3 = [service3, service1, service2];

  return (
    <section
      id="doradztwo"
      onKeyDown={handleKeydown}
      className="servicesWrapper"
    >
      <h2 className="servicesHeading">Usługi</h2>
      <div className="servicesGrid">
        <div id="gridCell1" className="gridTextbox">
          <h2 className="gridHeading">Doradzimy</h2>
          <p className="gridParagraph">
            Do każdego klienta podchodzimy indywidualnie, a na każdym etapie
            realizacji usługi możesz liczyć na fachowe doradztwo, aby
            zoptymalizować koszty lub funkcjonalność projektu.
          </p>
        </div>

        <div className="gridImg" id="gridCell2">
          <Swiper
            slidesPerView={1}
            autoplay={{ autoplay: true, delay: 4000 }}
            preventInteractionOnTransition={true}
            allowTouchMove={false}
            speed={1200}
          >
            {slides1.map((slide, i) => (
              <SwiperSlide key={i}>
                <div
                  className="gridImg"
                  style={{ backgroundImage: `url(${slide})` }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div id="gridCell3" className="gridImg">
          <Swiper
            id="materialy"
            slidesPerView={1}
            autoplay={{ autoplay: true, delay: 4000 }}
            preventInteractionOnTransition={true}
            allowTouchMove={false}
            speed={1200}
            modules={[EffectCube]}
            effect="cube"
          >
            {slides2.map((slide, i) => (
              <SwiperSlide key={i}>
                <div
                  className="gridImg"
                  style={{ backgroundImage: `url(${slide})` }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div id="gridCell4" className="gridTextbox">
          <h2 className="gridHeading">Wybór Materiałów</h2>
          <p className="gridParagraph">
            Nasza firma specjalizuje się w lekkiej zabudowie (G-K). Płyty
            gipsowo-kartonowe to uniwersalne rozwiązanie, stosowane przez wielu
            specjalistów. Wykorzystuje się je do budowy ścian działowych,
            podwieszanych sufitów oraz okładzin ściennych, które zastępują
            tradycyjny tynk.
          </p>
          <ButtonPrimary
            onClick={() => setIsClicked(true)}
            className="gridButton"
          >
            Dowiedz się więcej &rarr;
          </ButtonPrimary>
        </div>
        <div id="gridCell5" className="gridTextbox">
          <h2 className="gridHeading">Wykonanie</h2>
          <p className="gridParagraph">
            Nasz wyspecjalizowany zespół posiada wiedzę oraz niezbędne narzędzia
            do przeprowadzenia możliwie jak najszybszego, sprawnego i
            kompleksowego montażu, bądź wykończenia Twojej nieruchomości
            włącznie z np. elektroniką, klimatyzacją czy hydrauliką.
          </p>
        </div>
        <div id="gridCell6" className="gridImg">
          <Swiper
            id="wykonanie"
            slidesPerView={1}
            autoplay={{ autoplay: true, delay: 4000 }}
            preventInteractionOnTransition={true}
            allowTouchMove={false}
            speed={1200}
          >
            {slides3.map((slide, i) => (
              <SwiperSlide key={i}>
                <div
                  className="gridImg"
                  style={{
                    backgroundImage: `url(${slide})`,
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {isClicked ? <Overlay handleClick={handleClick} /> : null}
    </section>
  );
}

export default Services;
