import React from "react";
import { HiOutlineMail, HiPhone } from "react-icons/hi";

function ContactBar() {
  return (
    <div className="contactBarWrapper">
      <a
        href="mailto:artur.zielinski1977@gmail.com"
        className="contactBarLink contactBarItem"
      >
        <HiOutlineMail className="contactBarIcon" />
        <span>artur.zielinski1977@gmail.com</span>
      </a>

      <a href="tel:+48696680896" className="contactBarLink contactBarItem">
        <HiPhone className="contactBarIcon" />
        <span>696 680 896</span>
      </a>
    </div>
  );
}

export default ContactBar;
