import React from "react";
import grid1 from "../assets/1.jpg";
import grid2 from "../assets/2.jpg";
import grid3 from "../assets/3.jpg";
import grid4 from "../assets/4.jpg";
import grid5 from "../assets/5.jpg";
import grid6 from "../assets/6.jpg";
import grid7 from "../assets/7.jpg";
import grid8 from "../assets/8.jpg";
import grid9 from "../assets/9.jpg";

function GridGallery() {
  return (
    <div className="gridGallery">
      <div className="gridCellImg">
        <img className="gridCell" src={grid1} alt="Zdjęcie projektu" />
      </div>
      <div className="gridCellImg">
        <img className="gridCell" src={grid2} alt="Zdjęcie projektu" />
      </div>
      <div className="gridCellImg">
        <img className="gridCell" src={grid3} alt="Zdjęcie projektu" />
      </div>
      <div className="gridCellImg">
        <img className="gridCell" src={grid4} alt="Zdjęcie projektu" />
      </div>
      <div className="gridCellImg">
        <img className="gridCell" src={grid5} alt="Zdjęcie projektu" />
      </div>
      <div className="gridCellImg">
        <img className="gridCell" src={grid6} alt="Zdjęcie projektu" />
      </div>
      <div className="gridCellImg">
        <img className="gridCell" src={grid7} alt="Zdjęcie projektu" />
      </div>
      <div className="gridCellImg">
        <img className="gridCell" src={grid8} alt="Zdjęcie projektu" />
      </div>
      <div className="gridCellImg">
        <img className="gridCell" src={grid9} alt="Zdjęcie projektu" />
      </div>
    </div>
  );
}

export default GridGallery;
