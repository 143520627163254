import React from "react";
import Intro from "../components/Intro";
import ContainerWrapper from "../components/ContainerWrapper";
import Services from "../components/Services";
import GridDivider from "../components/GridDivider";
import Gallery from "../components/Gallery";

function Main() {
  return (
    <div>
      <main>
        <ContainerWrapper>
          <Intro />
        </ContainerWrapper>
        <Services />
        <GridDivider />
        <Gallery />
      </main>
    </div>
  );
}

export default Main;
