import React from "react";

function CopyrightBar() {
  return (
    <div className="copyright">
      &copy; Copyright A-Z Gips Artur Zieliński. Wszelkie prawa zastrzeżone |
      Stworzone przez{" "}
      <a
        href="https://bartjozef.com"
        rel="noreferrer"
        className="copyrightLink"
      >
        Bartosz Józefowicz
      </a>
    </div>
  );
}

export default CopyrightBar;
