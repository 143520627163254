import React from "react";
import modal from "../assets/modal.jpg";
import { MdClose } from "react-icons/md";

function Modal({ handleClick }) {
  return (
    <div className="modal">
      <div className="modalFlex">
        <div
          className="modalImg"
          style={{ backgroundImage: `url(${modal})` }}
        ></div>
        <div className="modalTextbox">
          <h3 className="modalHeading">Zabudowy G-K</h3>
          <p className="modalParagraph">
            Płyty gipsowo-kartonowe (G-K) odpowiednio dobrane sprawdzają się w
            każdym pomieszczeniu, niezależnie od wielkości. Stanowią one więc
            bardzo dobry podkład pod różnego rodzaju materiały wykończeniowe
            takie jak między innymi tapety, farby czy płytki ceramiczne.
          </p>
          <p className="modalParagraph">
            Ściany działowe wykonane z płyt gipsowych mogą mieć nie tylko
            proste, ale również nietypowe kształty. Cechuje je również to, że
            mają wysoką izolacyjność akustyczna i wznoszenie takich ścian
            zajmuje zaledwie kilka dni.
          </p>
        </div>
      </div>
      <button onClick={handleClick} className="modalButton">
        <MdClose className="modalIcon" />
      </button>
    </div>
  );
}

export default Modal;
