import React, { useState } from "react";
import logo from "../assets/logo.png";
import Dropdown from "./Dropdown";
import { RiArrowDropDownLine } from "react-icons/ri";

function Navigation() {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () =>
    setIsClicked((prevState) => {
      return prevState ? false : true;
    });

  return (
    <div className="navigationWrapper">
      <div className="navigationLogo">
        <img src={logo} alt="Logo" />
      </div>
      <h1 className="navigationHeading">
        A-Z GIPS <br /> Artur Zieliński
      </h1>
      <nav className="navigationNav">
        <ul className="navigationList">
          <li className="navigationItem">
            <a href="/" className="navigationButton">
              Strona Główna
            </a>
          </li>
          <li className="navigationItem">
            <button onClick={handleClick} className="navigationButton">
              <div className="navButtonFlex">
                Usługi
                <span>
                  <RiArrowDropDownLine
                    className={`navigationButtonIcon ${
                      isClicked ? "rotate" : ""
                    }`}
                  />
                </span>
              </div>
            </button>
          </li>
          <li className="navigationItem">
            <a href="#projekty" className="navigationButton">
              Projekty
            </a>
          </li>
          <li className="navigationItem">
            <a href="#kontakt" className="navigationButton">
              Kontakt
            </a>
          </li>
          <Dropdown
            onLoseFocus={() => setIsClicked(false)}
            className={`navigationDropdown ${
              isClicked ? "navigationDropdownOpen" : ""
            }`}
          />
        </ul>
      </nav>
    </div>
  );
}

export default Navigation;
