import React from "react";
import Wrapper from "./Wrapper";
import ContactBar from "../components/ContactBar";
import NavigationBar from "../components/NavigationBar";
import HeroImgBar from "../components/HeroImgBar";
import hero from "../assets/az-hero.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, A11y } from "swiper";
import "swiper/css";
import "swiper/css/bundle";

SwiperCore.use([Autoplay, A11y]);

function Header() {
  const slides = [
    "Wykonawstwo zabudowy biur, lokali usługowych i powierzchni magazynowych.",
    "Wykończenia wnętrz od A do Z.",
    "Materiały i wykonanie na najwyższym poziomie.",
  ];
  return (
    <header>
      <ContactBar />
      <Wrapper
        className="heroWrapper"
        style={{ backgroundImage: `var(--gradient-primary-op), url(${hero})` }}
      >
        <NavigationBar />
        <Swiper
          slidesPerView={1}
          autoplay={{ autoplay: true, delay: 4000 }}
          preventInteractionOnTransition={true}
          allowTouchMove={false}
          speed={800}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <h2 className="heroHeading">{slide}</h2>
            </SwiperSlide>
          ))}
        </Swiper>
        <a href="#intro" className="heroButton">
          Dowiedz się więcej &darr;
        </a>
      </Wrapper>
      <HeroImgBar />
    </header>
  );
}

export default Header;
