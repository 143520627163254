import React from "react";

function Wrapper({ children, className, style }) {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

export default Wrapper;
