import React from "react";
import Contact from "../components/Contact";
import CopyrightBar from "../components/CopyrightBar";

function Footer() {
  return (
    <footer>
      <Contact />
      <CopyrightBar />
    </footer>
  );
}

export default Footer;
