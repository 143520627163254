import Wrapper from "./layout/Wrapper.jsx";
import Header from "./layout/Header.jsx";
import Main from "./layout/Main.jsx";
import Footer from "./layout/Footer.jsx";

function App() {
  return (
    <>
      <Wrapper>
        <Header />
        <Main />
        <Footer />
      </Wrapper>
    </>
  );
}

export default App;
