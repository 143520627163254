import React from "react";
import ContainerWrapper from "./ContainerWrapper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import slide1 from "../assets/gallery/1.jpg";
import slide2 from "../assets/gallery/2.jpg";
import slide3 from "../assets/gallery/3.jpg";
import slide4 from "../assets/gallery/4.jpg";
import slide5 from "../assets/gallery/5.jpg";
import slide6 from "../assets/gallery/6.jpg";
import slide7 from "../assets/gallery/7.jpg";
import slide8 from "../assets/gallery/8.jpg";
import slide9 from "../assets/gallery/9.jpg";
import slide10 from "../assets/gallery/10.jpg";
import slide11 from "../assets/gallery/11.jpg";
import slide12 from "../assets/gallery/12.jpg";
import slide13 from "../assets/gallery/13.jpg";

SwiperCore.use([Scrollbar, A11y]);

function Gallery() {
  const slides = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
    slide12,
    slide13,
  ];
  return (
    <section id="projekty" className="gallery">
      <h2 className="galleryHeading">Nasze Projekty</h2>
      <ContainerWrapper>
        <Swiper
          slidesPerView={1}
          scrollbar={{ draggable: true }}
          preventInteractionOnTransition={true}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <div
                className="gallerySlide"
                style={{ backgroundImage: `url(${slide})` }}
              ></div>
            </SwiperSlide>
          ))}
        </Swiper>
        <p className="galleryParagraph">
          Powyższe zdjęcia przedstawiają prace wykonane przez{" "}
          <span className="highlight">A-Z Gips Artur Zieliński</span>.
        </p>
      </ContainerWrapper>
    </section>
  );
}

export default Gallery;
