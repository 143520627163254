import React from "react";
import btn1 from "../assets/herobtn1.jpg";
import btn2 from "../assets/herobtn2.jpg";
import btn3 from "../assets/herobtn3.jpg";

function HeroImgBar() {
  return (
    <div className="heroImgBar">
      <a
        href="#doradztwo"
        style={{ backgroundImage: `var(--gradient-primary-op), url(${btn1})` }}
        className="heroImgBarButton"
      >
        Doradztwo
      </a>
      <a
        href="#materialy"
        style={{ backgroundImage: `var(--gradient-primary-op), url(${btn2})` }}
        className="heroImgBarButton"
      >
        Materiały
      </a>
      <a
        href="#wykonanie"
        style={{ backgroundImage: `var(--gradient-primary-op), url(${btn3})` }}
        className="heroImgBarButton"
      >
        Wykonanie
      </a>
    </div>
  );
}

export default HeroImgBar;
