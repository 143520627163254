import React from "react";

function Dropdown({ className, onLoseFocus }) {
  return (
    <ul className={className}>
      <li className="dropdownItem">
        <a href="#doradztwo" onClick={onLoseFocus} className="navigationButton">
          Doradztwo
        </a>
      </li>
      <li className="dropdownItem">
        <a href="#materialy" onClick={onLoseFocus} className="navigationButton">
          Materiały
        </a>
      </li>
      <li className="dropdownItem">
        <a href="#wykonanie" onClick={onLoseFocus} className="navigationButton">
          Wykonanie
        </a>
      </li>
      <li className="dropdownItem">
        <a
          href="#wykonczenia"
          onClick={onLoseFocus}
          className="navigationButton"
        >
          Wykończenia
        </a>
      </li>
    </ul>
  );
}

export default Dropdown;
