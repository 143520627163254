import React from "react";
import Modal from "./Modal";
import ContainerWrapper from "./ContainerWrapper";

function Overlay({ handleClick }) {
  const handleOverlayClose = (e) => {
    if (e.target.classList.contains("overlay")) handleClick();
  };
  const handleKeydown = (e) => {
    if (e.key === "Escape") handleClick();
  };

  return (
    <div
      onKeyDown={handleKeydown}
      onClick={handleOverlayClose}
      className="overlay"
    >
      <ContainerWrapper>
        <Modal handleClick={handleClick} />
      </ContainerWrapper>
    </div>
  );
}

export default Overlay;
