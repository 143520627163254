import React from "react";
import GridGallery from "./GridGallery";
import { TiTick } from "react-icons/ti";

function GridDivider() {
  return (
    <section id="wykonczenia" className="gridDivider">
      <h2 className="gridDividerHeading">Kompleksowe Wykończenia Wnętrz</h2>

      <div className="gridDividerFlex">
        <GridGallery />
        <div className="gridDividerTextbox">
          <h3 className="gridDividerHeadingSecondary">
            Poza standardową ofertą, zajmujemy się również wykończeniami
            mieszkań.
          </h3>
          <p className="gridDividerParagraph">
            Skontaktuj się z nami, a my dołożymi wszelkich starań, aby Twoje
            mieszkanie wyglądało tak, jak sobie wymarzysz.
          </p>

          <h3 className="gridDividerHeadingTertiary">
            Doradzimy kompleksowo na temat:
          </h3>
          <div className="gridDividerFlexbox">
            <TiTick className="gridDividerIcon" />
            <span>Wyposażenia</span>
          </div>
          <div className="gridDividerFlexbox">
            <TiTick className="gridDividerIcon" />
            <span>Wykończeń</span>
          </div>
          <div className="gridDividerFlexbox">
            <TiTick className="gridDividerIcon" />
            <span>Materiałów</span>
          </div>
          <p className="gridDividerParagraph2">
            Zapewniamy, że będziesz zachwycony efektem końcowym!
          </p>
          <a href="#kontakt" className="btnPrimary gridDividerButton">
            Skontaktuj się z nami &darr;
          </a>
        </div>
      </div>
    </section>
  );
}

export default GridDivider;
