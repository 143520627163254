import React from "react";

function Contact() {
  return (
    <section id="kontakt" className="contactWrapper">
      <p className="contactParagraph">Masz pytania?</p>
      <h2 className="contactHeading">Napisz do nas!</h2>
      <div className="formWrapper">
        <form
          id="contact-form"
          action="https://formspree.io/f/mnqyqabp"
          method="post"
          className="ctaForm"
        >
          <div className="ctaFormInputs">
            <input
              type="text"
              id="name-input"
              name="name-input"
              className="formInput"
              placeholder="Imię"
              autoComplete="off"
              required
            />
            <input
              type="email"
              id="email-input"
              name="email-input"
              className="formInput"
              placeholder="Email"
              autoComplete="off"
              required
            />
            <input
              type="text"
              id="subject-input"
              name="subject-input"
              className="formInput"
              placeholder="Temat"
              autoComplete="off"
              required
            />
          </div>

          <textarea
            name="textarea"
            id="textarea"
            className="formTextarea"
            placeholder="Twoja Wiadomość"
            rows={5}
            autoComplete="off"
            required
          ></textarea>
        </form>
        <button
          form="contact-form"
          type="submit"
          className="btnPrimary contactFormButton"
        >
          Wyślij
        </button>
      </div>
    </section>
  );
}

export default Contact;
