import React from "react";

function Intro() {
  return (
    <section id="intro" className="introWrapper">
      <div className="introHeadingBox">
        <h3 className="introHeadingPrimary">Nasza działalność</h3>
        <h2 className="introHeading">A-Z Gips – Artur Zieliński</h2>
        <p className="introHeadingSecondary">
          ZREALIZUJEMY DLA CIEBIE KAŻDY PROJEKT!
        </p>
        <a href="#projekty" className="btnPrimary introButton">
          Odwiedź galerię &rarr;
        </a>
      </div>
      <div className="introTextbox">
        <p className="introParagraph">
          Przez wiele lat nasza firma zdobyła wszechstronne i niezbędne
          doświadczenie dla zapewnienia wysokiej jakości efektu końcowego.
          Niezależnie od tego czy to biuro, lokal usługowy czy nawet cały dom,
          polegamy na naszych doświadczonych specjalistach, którzy zajmą się
          wszystkim i przeprowadzą transformujące i doskonałej jakości prace na
          Twojej nieruchomości. Ściśle współpracujemy z Tobą, aby upewnić się,
          że Twoje pomysły przełożą się na udany projekt z rewelacyjnym efektem.
        </p>
      </div>
    </section>
  );
}

export default Intro;
